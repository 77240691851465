/* You can add global styles to this file, and also import other style files */
$spacer: 16px;
@import "bootstrap";
@import "./assets/variables.scss";
@import "./assets/data-tables.scss";

// Overide popover classes

.popover {
    --bs-popover-font-size: 14px;
    --bs-popover-header-padding-y: 8px;
    --bs-popover-header-font-size: 16px;
    --bs-popover-arrow-width: 16px;
    --bs-popover-arrow-height: 8px;
    --bs-popover-max-width: auto;
    --bs-popover-border-width: none;
}

// Override bootstrap classes

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    --bs-gutter-x: 24px;
}

.mat-body,
.mat-body-2,
.mat-typography {
    letter-spacing: 0.28px;
}

.mat-mdc-dialog-container {
    --mdc-dialog-subhead-tracking: 0.2px;
    --mdc-dialog-supporting-text-tracking: 0.5px;
}

.mat-mdc-card {
    --mat-card-title-text-tracking: 0.2px;
    --mat-card-subtitle-text-tracking: 0.11px;
    --mat-card-title-text-line-height: $font-size-subtitle-2;
}

// Grid classes
.row {
    --bs-gutter-x: 16px;
}

.w-100 {
    width: 100%;
}

.lead {
    font-size: 20px;
    font-weight: 300;
}

// Button classes
.btn-sm,
.btn-group-sm,
.btn {
    --bs-btn-padding-y: 4px;
    --bs-btn-padding-x: 8px;
    --bs-btn-font-size: 14px;
    --bs-border-radius-sm: 4px;
    --bs-btn-focus-box-shadow: 0 0 0 4px;
}

// Table casses

.table> :not(caption)>*>* {
    padding: 8px 8px;
}

// Override swal classes
.swal2-popup {
    font-size: 14px;
}

.swal2-title {
    font-size: 24px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html:focus-within {
    scroll-behavior: smooth;
    font-size: 62.5%;
}

html {
    --mat-toolbar-title-text-tracking: 0.2px;
    --mat-expansion-container-text-tracking: 0.28px;
    --mdc-filled-text-field-label-text-tracking: 0.5px;
    --mdc-outlined-text-field-label-text-tracking: 0.5px;
    --mat-form-field-container-text-tracking: 0.5px;
    --mat-form-field-subscript-text-tracking: 0.53px;
    --mat-optgroup-label-text-tracking: 0.5px;
    --mat-option-label-text-tracking: 0.5px;
    --bs-border-radius-pill: 50rem;
    --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);

}

html,
body,
#root {
    height: 100vh;
    width: 100%;
    --bs-body-font-size: 16px;
    --bs-border-radius: 6px;
    --bs-border-radius-sm: 4px;
    --bs-border-radius-lg: 8px;
    --bs-border-radius-xl: 16px;
    --bs-border-radius-xxl: 32px;
    --bs-border-radius-pill: 800px;
    --bs-focus-ring-width: 4px;
    --mat-bottom-sheet-container-text-tracking: 0.28px;
    --mat-table-header-headline-tracking: 0.11px;
    --mat-table-row-item-label-text-tracking: 0.28px;
}

body {
    text-rendering: optimizeSpeed;
    margin: 0 auto;
    min-width: 345px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: $grey-lighten-3;
}

:root :host {
    --fa-font-brands: normal 400 16px/1 'Font Awesome 6 Brands';
}

.action-btn {
    padding: 4px 5px;
    border-radius: 3px;
    border: 1px solid;
    align-items: center;
    cursor: pointer;

    .btn-img {
        display: block;
        width: 12px;
        height: 12px;
    }

    &:hover {
        opacity: 0.9;
    }

    &.edit {
        background-color: #ec971f;
        border-color: #e08e0b;
    }

    &.delete {
        background-color: #dd4b39;
        border-color: #da422f;
    }

    &.action-success {
        background-color: rgba(57, 97, 44, 0.776);
        border-color: rgba(57, 97, 44, 0.776);
    }

    &.action-danger {
        background-color: #dd4b39;
        border-color: #da422f;
    }

    &.action-info {
        background-color: rgba(30, 107, 140, 0.776);
        border-color: rgba(30, 107, 140, 0.776);
    }

    &.signatures {
        background-color: #3c8dbc;
        border-color: #367fa9;
    }

    &.historic {
        background-color: #808080;
        border-color: #555555;
    }
}

.justify-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.align-items {
    display: flex;
    align-items: center;
}

.hidden {
    display: none;
}

// custom spinner class for all application
.section-spinner {
    z-index: 99999;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    & .mat-mdc-progress-spinner {
        --mdc-circular-progress-active-indicator-color: white;
    }

    & .spinner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: white;
        align-items: center;
    }
}

.order-section-dt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & span.order {
        border: 1px solid #ccc;
        padding: 0px 10px;
    }
}

// classes/overrides of specific zones
.mat-button-toggle-button {
    width: 100%;
    text-align: left !important;

    & .mat-button-toggle-label-content {
        width: 100%;
    }
}

.red-text {
    color: red;
}

.green-text {
    color: green;
}

// override angular-editor
.fit-screen .angular-editor .angular-editor-wrapper .angular-editor-textarea {
    max-height: 40vh !important;
}

.custom-dt tr td {
    vertical-align: top;
}

// cereri custom styles, global availability
.box-wrapper {
    border: 2px solid #888;
    margin-bottom: 10px;
}

.box-content-header {
    background: #888;
    color: white;

    & .row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    & .mat-mdc-card-title {
        margin-left: 10px;
    }
}

.pull-right {
    float: right;
}

.badge {
    padding: 5px;
}

.badge-success {
    background: green;
}

.badge-danger {
    background: red;
}

.card-container mat-form-field {
    width: 100%;
}

.label {
    border-radius: 7px;
    padding: 4px 8px;
}

.label-success {
    background-color: #00a65a;
    color: #fff;
}

.label-danger {
    background-color: #dd4b39;
    color: #fff;
}