@import "../assets/variables.scss";


.btn-white {
    background-color: #f4f4f4;
}

.section {
    border-radius: 3px;
    border-top: 3px solid #d2d6de;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    .section-top {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #f4f4f4;

        .section-title {
            display: inline-block;
            font-size: 18px;
            margin-bottom: 0;
        }

        .section-btn {
            display: flex;
            align-items: center;
            background-color: #00a65a;
            border: 1px solid #009953;
            color: #ffffff;
            padding: 4px 4px;
            border-radius: 3px;
            font-size: 12px;
            cursor: pointer;

            img {
                margin-right: 4px;
                width: 12px;
                height: 12px;
            }
        }

        .section-btn:hover {
            opacity: 0.9;
        }
    }

    .table-container {
        padding: 10px;
        margin-bottom: 550px;
    }

    .spinner {
        display: flex;
        justify-content: center;
    }

    .row-border {
        min-width: auto;
        margin: 0px;
    }
}

.add-container {
    padding: 20px;

    h1 {
        padding-bottom: 20px;
    }

    .add-card__form {
        .add-field {
            mat-form-field {
                width: 100%;
            }
        }

        .add-action-btns {
            margin: 110px 0 0 350px;
        }
    }

    .spinner {
        display: flex;
        justify-content: center;
    }
}

.edit-container {
    padding: 20px;

    h1 {
        padding-bottom: 20px;
    }

    .edit-card__form {
        .edit-field {
            mat-form-field {
                width: 100%;
            }
        }

        .edit-action-btns {
            margin: 110px 0 0 350px;
        }
    }

    .spinner {
        display: flex;
        justify-content: center;
    }
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 1020px;
    position: absolute;
    z-index: 999;
    background-color: $grey-lighten-5;
    border: 1px solid $grey-lighten-1;
    border-radius: 4px;
    margin-top: 40px;
    margin-left: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.open-filter-btn {
    margin: 10px 10px;
    background-color: $default-white;
    border: none;
    cursor: pointer;

    img {
        width: 15px;
        height: 15px;
    }
}

.open-filter-btn.active {
    background-color: $grey-lighten-5;
    color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
    /* Add a shadow when active */
}

.request-priority-container {
    padding: 20px;

    h1 {
        padding-bottom: 20px;
    }

    .request-priority-card__form {

        .request-priority-name,
        .request-priority-time {
            mat-form-field {
                width: 500px;
            }
        }

        .request-priority-color {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            span {
                font-size: $font-size-heading-6;
                color: $grey-darken-1;
            }

            input {
                margin-left: 10px;
                border: none;
                background-color: transparent;
                cursor: pointer;
            }
        }

        .request-priority-action-btns {
            margin: 110px 0 0 350px;
        }
    }

    .spinner {
        display: flex;
        justify-content: center;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.solicitare-container {
    padding: 20px;

    h1 {
        padding-bottom: 20px;
    }

    .solicitare-card__form {
        .solicitare-nume {
            width: 300px;

            mat-form-field {
                width: 500px;
            }
        }

        .solicitare-slug {
            mat-form-field {
                width: 500px;
            }
        }

        .solicitare-observatii {
            mat-form-field {
                width: 500px;
            }
        }

        .solicitare-action-btns {
            margin: 110px 0 0 350px;
        }
    }

    .spinner {
        display: flex;
        justify-content: center;
    }
}

.status-container {
    height: 500px;
    padding: 20px;

    h1 {
        padding-bottom: 20px;
    }

    .status-card__form {

        .status-name,
        .status-observations {
            mat-form-field {
                width: 500px;
            }
        }

        .status-color {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            span {
                font-size: $font-size-heading-6;
                color: $grey-darken-1;
            }

            input {
                margin-left: 10px;
                border: none;
                background-color: transparent;
                cursor: pointer;
            }
        }

        .status-action-btns {
            margin: 110px 0 0 350px;
        }
    }

    .spinner {
        display: flex;
        justify-content: center;
    }
}

.request-priority-container {
    padding: 20px;

    h1 {
        padding-bottom: 20px;
    }

    .request-priority-card__form {

        .request-priority-name,
        .request-priority-time {
            mat-form-field {
                width: 500px;
            }
        }

        .request-priority-color {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            span {
                font-size: $font-size-heading-6;
                color: $grey-darken-1;
            }

            input {
                margin-left: 10px;
                border: none;
                background-color: transparent;
                cursor: pointer;
            }
        }

        .request-priority-action-btns {
            margin: 110px 0 0 350px;
        }
    }

    .spinner {
        display: flex;
        justify-content: center;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}